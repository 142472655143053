import { BlockComponent } from "../../framework/src/BlockComponent";
import {
  QuickBlox,
  QBUser,
  QBWebRTCSession,
} from "quickblox/quickblox";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
const configJSON = require("./config");

export type TabValue =
  | "dashboard"
  | "patient"
  | "task_list"
  | "visit_schedule"
  | "send_notification"
  | "community";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selected?: TabValue
  language?: string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  selected: TabValue;
  showModal: boolean;
  isCalling: boolean;
  patientsIds: any;
  usersChatList: any;
  userIdDialog: string | undefined;
  userDetail: any
  sessionState: any
  // Customizable Area End
}
interface SS {
  id: any;
}

class SidebarController extends BlockComponent<Props, S, SS> {
  QB = new QuickBlox();
  constructor(props: Props) {
    super(props);

    this.state = {
      selected: "dashboard",
      showModal: false,
      isCalling: false,
      patientsIds: [],
      usersChatList: {},
      userIdDialog: "",
      userDetail: {},
      sessionState: {}
    };
  }

 
  componentDidMount = async () => {
    this.connectQuickBloxServer(this.QB)
    this.QB.webrtc.onCallListener = this.handleCallListner

    this.setState({selected: this.props.selected || "dashboard"})
  }

  handleCallListner = (session: any, extension: any) => {
    const userID = localStorage.getItem('userId')
    let user;
    const users = [session.currentUserID, session.initiatorID].filter((i: any) => {
      if(String(i) !== userID) {
        user = i
      }
    })

    this.setState({ isCalling: true, userDetail: extension, userIdDialog: user, sessionState: session })
  };

  handleCallRejectListner = (session: QBWebRTCSession, userId: QBUser['id'], extension: Record<string, any>) => { 
    session.reject(extension)
   };

   handleCallReject = () => {
    this.setState({ isCalling: false});

    this.state.sessionState.reject({})
   }
   
  connectQuickBloxServer = async (QB:any) => {
    const storedCredentialsString: string | null | any = localStorage.getItem('quickBloxCredentials');
    const storedCredentials = JSON.parse(storedCredentialsString);
  
    const CONFIG = {
      // other settings
      webrtc: {
        disconnectTimeInterval: 86400,
      }
      // other settings
    };
  
    QB.init(storedCredentials?.appId, storedCredentials?.authKey, storedCredentials?.authSecret, storedCredentials?.accountKey, CONFIG);
  
    const userUniqnueId: any = localStorage.getItem("userUniqnueId");
    const userPassword = "Password@123";
  
    const paramsLogin = {
      login: userUniqnueId,
      password: userPassword,
    };
    QB.createSession(paramsLogin, async (error: any, result: any) => {
  
      localStorage.setItem("userId", result.user_id);
      localStorage.setItem("chatToken", result.token);
  
      QB.login(paramsLogin, (error: any, result: any) => {})
      const Login = {
        userId: result.user_id,
        password: userPassword,
      };
  
      QB.chat.connect(Login, (err: any, result: any) => {
        this.getListOfDialog()
      })
    });
  };

  handleNavigate = () => {
    localStorage.setItem("Iscalling", 'true')
    const user = this.state.usersChatList.find((obj: any) => obj.user_id == this.state.userIdDialog)

    localStorage.setItem("userDialog", JSON.stringify(user))
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),'Chat');
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(message);
  }

  getListOfDialog = () => {
    const params = {
      limit: 100,
    };
  
    this.QB.chat.dialog?.list(params, (error: any, dialogs: any) => {
      if (dialogs) {
        const patientData: any = JSON.parse(localStorage.getItem('patientData') || '[]');
        const mainData = dialogs.items.map((dialog: any) => {
          this.setState({ patientsIds: [...this.state.patientsIds, dialog.user_id] })
          const matchingPatient = patientData.find((patient: any) =>
            dialog.occupants_ids.includes(patient.user_data.quickblox_id)
          );
  
          if (matchingPatient) {
            return {
              ...dialog,
              full_name: matchingPatient.user_data.full_name,
            };
          }
          return dialog;
          })
          .filter((dialog: any) => dialog !== null)
        this.setState({ usersChatList: mainData });
      }
    });
  };

  selectTab = (key: TabValue) => {
    console.log("selected tab",key)
    this.setState({ selected: key });

    switch (key) {
      case "dashboard": {
        return this.props.navigation.navigate("Dashboard");
      }
      case "patient": {
        return this.props.navigation.navigate("Patient");
      }
      case "visit_schedule": {
        this.props.navigation.navigate("Appointments");
        return;
      }
      case "task_list": {
        this.props.navigation.navigate("TaskList");
        return;
      }
      case "send_notification": {
        this.props.navigation.navigate("Pushnotifications");
        return;
      }
      case "community": {
        this.props.navigation.navigate("CommunityForum");
        return;
      }

      default: {
        return this.openModal();
      }
    }
  };

  openModal = () => {
    this.setState({showModal: true})
  }

  closeModal = () => {
    this.setState({showModal: false})
  }

  getQuickBloxCredentials() {
    let storedCredentials = JSON.parse(configJSON.credsForQB);
    return {
      appId: storedCredentials.appId,
      authKey: storedCredentials.authKey,
      authSecret: storedCredentials.authSecret,
      accountKey: storedCredentials.accountKey,
    };
  }
}

export default SidebarController;
