import React from "react";
import DashboardController from "./DashboardController";
import { Accordion, AccordionDetails, AccordionSummary, Box, BoxProps, CircularProgress, Divider, Grid, Hidden, Typography, styled } from "@material-ui/core";
import SideNavbar from "../../../components/src/SideNavbar.web";
import Sidebar from "../../../components/src/Sidebar.web";
import { designPattern, designPattern2, imagePicture } from "./assets";
import CircularProgressWithLabel from "../../../components/src/CircularProgressWithLabel.web";
import AppAccordion from "../../../components/src/AppAccordion.web";
import { EventNote } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Milestone, progressSide, sideImage } from "./assets";

export default class Dashboard extends DashboardController {
  render() {
    const BoxView = Box as React.FC<BoxProps>
    const dashboard: { [key: string]: string } = {
      en: "Dashboard",
      fr: "Tableau de bord",
      es: "Panel",
      de: "Armaturenbrett",
    };
    const currentMilestone: { [key: string]: string } = {
      en: "Current Milestone",
      fr: "Jalon actuel",
      es: "Hito actual",
      de: "Aktueller Meilenstein",
    };
    const total: { [key: string]: string } = {
      en: "Total",
      fr: "Total",
      es: "Total",
      de: "Gesamt",
    };
    const milestone: { [key: string]: string } = {
      en: "Total",
      fr: "Jalon",
      es: "Hito",
      de: "Meilenstein",
    };
    const current: { [key: string]: string } = {
      en: "Current",
      fr: "Actual",
      es: "Actuel",
      de: "Aktueller",
    };
    const points: { [key: string]: string } = {
      en: "Points",
      fr: "Points",
      es: "Agujas",
      de: "Punkte",
    };
    const milestoneDetails: { [key: string]: string } = {
      en: "Milestone Details",
      fr: "Détails des jalons",
      es: "Detalles del hito",
      de: "Meilensteindetails",
    };
    const startDate: { [key: string]: string } = {
      en: "Start Date",
      fr: "Date de début",
      es: "Fecha de inicio",
      de: "Startdatum",
    };
    const duration: { [key: string]: string } = {
      en: "Duration",
      fr: "Durée",
      es: "Duración",
      de: "Dauer",
    };
    const endDate: { [key: string]: string } = {
      en: "End Date",
      fr: "Date de fin",
      es: "Fecha de finalización",
      de: "Enddatum",
    };
    const studyNumber: { [key: string]: string } = {
      en: "Study Number",
      fr: "Numéro d'étude",
      es: "Número de estudio",
      de: "Studiennummer",
    };
    const totalMilestone: { [key: string]: string } = {
      en: "Total milestone",
      fr: "Jalon total",
      es: "Hito total",
      de: "Gesamtmeilenstein",
    };
    const detailsOf: { [key: string]: string } = {
      en: "Details of",
      fr: "Détail de",
      es: "Detalle de",
      de: "Detail von",
    };
    const siteDetails: { [key: string]: string } = {
      en: "Site Details",
      fr: "Détails du site",
      es: "Detalles del sitio",
      de: "Site-Details",
    };
    const siteID: { [key: string]: string } = {
      en: "Site ID",
      fr: "Identifiant du site",
      es: "ID del sitio",
      de: "Site-ID",
    };
    const siteCoordinator: { [key: string]: string } = {
      en: "Site Coordinator",
      fr: "Coordinateur de chantier",
      es: "Coordinador del sitio",
      de: "Standortkoordinator",
    };
    const siteCountry: { [key: string]: string } = {
      en: "Site Country",
      fr: "Pays du site",
      es: "País del sitio",
      de: "Standortland",
    };
    const siteAddress: { [key: string]: string } = {
      en: "Site Address",
      fr: "Adresse du site",
      es: "Dirección del sitio",
      de: "Site-Adresse",
    };
    return (
      <>
        <SideNavbar
          navigation={this.props.navigation}
          id={this.props.id}
          showStudy={this.state.showStudy}
          showButtonChats
          showButtonNotification
          handleLanguageSwitch={this.handleLanguageSwitch}
          language={this.state.language}
        />

        <Box style={{ display: "flex",gap:"20px", flexDirection: "row", flexGrow: 1}}>
          <Sidebar navigation={this.props.navigation} id={this.props.id} language={this.state.language} />
          <Grid container spacing={2}>
          <Grid item xs={10} sm={9} style={{height:"100vh", overflow:"scroll", scrollbarWidth: "none",marginTop:"20px"}}>
          <ModalStyle>
              <BoxView className="subGrid">
                <Typography className="mainPatients">{dashboard[this.state.language] || dashboard['en']}</Typography>
                <Typography className="subText">Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium obcaecati architecto illum dicta repellat voluptates consequuntur quisquam nemo vel quo pariatur quaerat, dolore, voluptatum asperiores! Ex molestias velit voluptate maxime!</Typography>
                <Typography className="currentMilestone">{currentMilestone[this.state.language] || currentMilestone['en']}</Typography>
                <Divider className="divider" />
                 
                <Grid container xs={12} spacing={2} >
                   <Grid item xs={12} sm={6} md={6}>
                  <BoxView className="listItemone" style={{ position: "relative" }}>
                    <BoxView className="listbox12" style={{ position: "relative", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img src={progressSide} style={{ position: "absolute", right: "0", top: "0" }} />
                      <Box position="relative" style={{ left: "-20", top: "-35" }}>
                        <CircularProgress
                          variant="determinate"
                          value={100}
                          size={150}
                          thickness={4}
                          style={{ color: 'rgba(0,0,0,0.21', position: 'absolute',  }}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={this.callPercentage()}
                          size={150}
                          thickness={4}
                          style={{ color: 'white', zIndex: 1, strokeLinecap: 'round' }}
                        />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          zIndex={3}
                        >
                          <Typography variant="caption" style={{ color: "white", fontFamily: "Jost", fontWeight: 700, fontSize: "30px" }}>
                          {this.callPercentage()}%
                          </Typography>
                        </Box>
                      </Box>
                    </BoxView>
                  </BoxView>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                  <BoxView className="listItemtwo">
                    <BoxView className="listbox">
                      <Typography className="total">{this.state.total_Milestones}</Typography>
                          <Grid container xs={12} spacing={2} >
                            <Grid item xs={12} sm={6}>
                        <BoxView >
                          <Typography className="totalDashbord">{total[this.state.language] || total['en']}</Typography>
                          <Typography className="milestone">{milestone[this.state.language] || milestone['en']}</Typography>
                        </BoxView>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                        <BoxView>
                          <img src={Milestone} height={40} width={40} />
                        </BoxView>
                            </Grid>
                          </Grid>
                    </BoxView>
                  </BoxView>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                      <BoxView className="listItemtwo">
                        <BoxView className="listbox">
                          <Typography className="total">{this.state.current_Milestones}</Typography>
                          <Grid container xs={12} spacing={2} >
                            <Grid item xs={12} sm={6}>
                              <BoxView >
                                <Typography className="totalDashbord">{current[this.state.language] || current['en']}</Typography>
                                <Typography className="milestone">{milestone[this.state.language] || milestone['en']}</Typography>
                              </BoxView>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <BoxView>
                                <img src={Milestone} height={40} width={40} />
                              </BoxView>
                            </Grid>
                          </Grid>
                        </BoxView>
                      </BoxView>
                    </Grid>
                </Grid>

                <Typography className="MilestoneDetailsFirst">{milestoneDetails[this.state.language] || milestoneDetails['en']}</Typography>
                  {this.state.milestones?.map((milestone: any) => (
                    <Accordion id="MilestonesAccordion" key={milestone.id} className="accordionFirst" defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="Milestone1">{milestone.attributes ? milestone.attributes.name : ""}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: milestone.attributes?.milestone_description }} />
                      </AccordionDetails>
                      <Divider style={{ margin: "0px 20px" }} />
                      <AccordionDetails>
                        <Grid container >
                          <Grid item xs={12} sm={6}>
                            <Typography className="accordionDetailes">
                            {points[this.state.language] || points['en']}:
                            </Typography>
                            <Typography className="accordionSubDetailesSecound" >
                              15 Points
                            </Typography>
                            <Typography className="accordionDetailes">
                            {startDate[this.state.language] || startDate['en']}:
                            </Typography>
                            <Typography className="accordionSubDetailesFirst">
                              <EventNote /> {this.formatDate(milestone.attributes?.start_date)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className="accordionDetailes">
                            {duration[this.state.language] || duration['en']}:
                            </Typography>
                            <Typography className="accordionSubDetailesSecound">
                              {this.calculateDuration(milestone.attributes?.start_date, milestone.attributes?.end_date)}
                            </Typography>

                            <Typography className="accordionDetailes">
                            {endDate[this.state.language] || endDate['en']}
                            </Typography>
                            <Typography className="accordionSubDetailesFirst">
                              <EventNote />  {this.formatDate(milestone.attributes?.end_date)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                <Typography className="MilestoneDetails">{this.state.studyName}</Typography>
                <Divider className="divider" />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <BoxView className="boxDetails">
                        <Typography className="studyNumber">
                        {studyNumber[this.state.language] || studyNumber['en']}:
                        </Typography>
                        <Typography className="accordionSubDetailes">
                          {this.state.studyNumber}
                        </Typography>
                      </BoxView>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <BoxView className="boxDetails">
                        <Typography className="accordionDetailes">
                          {totalMilestone[this.state.language] || totalMilestone['en']}:
                        </Typography>
                        <Typography className="accordionSubDetailes">
                          {this.formatNumber(this.state.milestones?.length)}
                        </Typography>
                      </BoxView>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <BoxView className="boxDetails">
                        <Typography className="accordionDetailes">
                        {duration[this.state.language] || duration['en']}:
                        </Typography>
                        <Typography className="accordionSubDetailes">
                          {this.calculateTotalDuration(this.state.milestones)}
                        </Typography>
                      </BoxView>
                    </Grid>
                  </Grid>
                <BoxView className="listItemBoxWrap">
                    <BoxView style={{ width: '100%' }}>
                      <Accordion id="StudyDescriptionAccordion" className="accordion" defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="detailOfTrail">{detailsOf[this.state.language] || detailsOf['en']} {this.state.studyName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <BoxView className="listboxTwo">
                            <img src={sideImage} width={153} />
                            <BoxView>
                              <Typography className="detailOfTrailText">
                                <div dangerouslySetInnerHTML={{ __html: this.state.studyDescription }} />
                              </Typography>
                            </BoxView>
                          </BoxView>
                        </AccordionDetails>
                        <Typography className="MilestoneDetailsSite">{siteDetails[this.state.language] || siteDetails['en']}</Typography>
                        <div>
                          {this.state.sites?.map((site: any, index: any) => (
                            <Accordion key={site.id} className="accordion" id="sitesAccordion" defaultExpanded>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index + 1}-content`}
                                id={`panel${index + 1}-header`}
                              >
                                <Typography className="site1">Site {index + 1}: {site.attributes?.site_name}</Typography>
                              </AccordionSummary>
                              <Divider style={{ margin: "0px 20px" }} />
                              <AccordionDetails>
                                <BoxView className="accordionDetails">
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={3}>
                                      <BoxView className="boxDetails">
                                        <Typography className="accordionDetailes">
                                          {siteID[this.state.language] || siteID['en']}:
                                        </Typography>
                                        <Typography className="accordionSubDetailes">
                                          {site?.id}
                                        </Typography>
                                      </BoxView>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <BoxView className="boxDetails">
                                        <Typography className="accordionDetailes">
                                        {siteCoordinator[this.state.language] || siteCoordinator['en']}:
                                        </Typography>
                                        <Typography className="accordionSubDetailes">
                                          {site.attributes?.site_name}
                                        </Typography>
                                      </BoxView>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <BoxView className="boxDetails">
                                        <Typography className="accordionDetailes">
                                        {siteCountry[this.state.language] || siteCountry['en']}:
                                        </Typography>
                                        <Typography className="accordionSubDetailes">
                                          {site.attributes?.site_country}
                                        </Typography>
                                      </BoxView>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                    <BoxView className="boxDetails">
                                      <Typography className="accordionDetailes">
                                        {siteAddress[this.state.language] || siteAddress['en']}:
                                      </Typography>
                                      <Typography className="accordionSubDetailes">
                                        {site.attributes?.site_address}
                                      </Typography>
                                    </BoxView>
                                    </Grid>
                                  </Grid>
                                </BoxView>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </div>
                      </Accordion>
                    </BoxView>
                  </BoxView>
              </BoxView>
          </ModalStyle>
          </Grid>
          <Grid item xs={2} sm={3}>
          </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

const ModalStyle = styled(Box)({

  "& .mainPatients": {
    color: "#1F3650",
    fontFamily: "Jost",
    fontSize: "40px",
    fontWeight: 700,
    lineHeight: "50px",
    marginBottom:"10px"
  },
  "& .subGrid": {
    width:'90%',
  },
  "& .subText": {
    color: "#414141",
    fontFamily: "Jost",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "26px",
    "@media (max-width: 500px)": {
     width:"86%"
  },
  },
  "& .listItemone": {
    backgroundColor: "#A046FB",
    marginTop: "30px",
    width: "100%",
    height: "219px",
    borderRadius: "8px",
    color: "#fff"
  },
  "& .listbox": {
    padding: "40px 25px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .listbox12": {
    padding: "40px 25px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .total": {
    color: "#4A4949",
    fontFamily: "Jost",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  "& .totalDashbord": {
    color: "#434343",
    fontFamily: "Jost",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px"
  },
  "& .listItemBoxWrap": {
    display: "flex",
  },
  "& .currentMilestone": {
    color: "#292929",
    fontFamily: "Jost",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "34px",
    marginTop: 30
  },
  "& .divider": {
    marginTop: 15,
    maxWidth: "100%"
  },
  "& .listitem": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .listItemtwo": {
    backgroundColor: "#F0F0F0",
    marginTop: "30px",
    width: "100%",
    height: "219px",
    borderRadius: "8px",
    color: "#1F3650"
  },
  "& .listItemthree": {
    backgroundColor: "#F0F0F0",
    marginTop: "30px",
    marginLeft: "25px",
    width: "206px",
    height: "219px",
    borderRadius: "8px",
    color: "#1F3650",
  },
  "& .milestone": {
    color: "#434343",
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px"
  },
  "& .MilestoneDetails": {
    color: "#292929",
    fontFamily: "Jost",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "34px",
    marginTop: 30
  },
  "& .MilestoneDetailsSite": {
    color: "#292929",
    fontFamily: "Jost",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    marginTop: 30,
    padding:"0px 16px"
  },
  "& .MilestoneDetailsFirst": {
    color: "#292929",
    fontStyle: "normal",
    marginTop: 30,
    fontFamily: "Jost",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
    textAlign: "left",
    marginBottom:"10px"
  },
  "& .Milestone1": {
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px"
  },
  "& .accordionDetailes": {
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px",
    marginRight: "20px"
  },
  "& .accordionSubDetailes": {
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .accordionSubDetailesSecound": {
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom:'30px'
  },
  "& .accordionSubDetailesFirst": {
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    gap:'5px'
  },
  "& .accordion": {
    marginTop: "16px",
    backgroundColor: "#f0f0f0",
    boxShadow: "none",
    borderRadius: "8px",
    width:'100%'
  },
  "& .accordionFirst": {
    marginTop: "22px",
    backgroundColor: "#f0f0f0",
    boxShadow: "none",
    borderRadius: "8px",
    width:"100%"
  },
  "& .accordionDetails": {
    marginTop: "15px",
    width:'100%'
  },
  "& .accordionDetailsFirst": {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginTop: "15px",
    marginBottom:"23px"
  },
  "& .subDetails": {
    display: "flex",
  },
  "& .boxDetails": {
    display: "flex",
    flexDirection: "column",
    gap: "5px"
  },
  "& .accordionDetailesTwo": {
    display: "flex",
    gap: "120px"
  },
  "& .studyNumber": {
    color: "#292929",
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px"
  },
  "& .listboxTwo": {
    padding: "25px",
  },
  "& .detailOfTrail": {
    color: "#292929",
    fontFamily: "Jost",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "34px",
    marginBottom: "12px"
  },
  "& .detailOfTrailText": {
    color: "#1F0603",
    fontFamily: "Jost",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
  "& .site1": {
    color: "#1F3650",
    fontFamily: "Jost",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px"
  }
})