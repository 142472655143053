// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { IAnswer, IAnswersByAccount, IQuestion } from "../../../components/src/Question.web";
export const configJSON = require("./config.js");


export interface IMilestone {
  id: number,
  study_id: number,
  start_date: string,
  end_date: string,
  milestone_description: string,
  name: string,
  created_at: string,
  updated_at: string,
}
export interface ITask {
  id: string,
  type: "task_list",
  attributes: {
      task_name: string,
      description: string,
      study_id: number,
      milestone_id: number,
      published: boolean,
      total_points: any,
      question_types: {
        data: IQuestion[]
      },
      total_task:number,
      completed_task:number,
      answers_by_account: IAnswersByAccount[]
  }
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  id: string;
  navigation: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  token: string;
  selectedMilestone: string,
  openMilestoneOptions: boolean,
  milestoneOptions: IMilestone[],
  isLoadingTaskList: boolean,
  taskList: ITask[],
  taskSelected: string,
  openSnackbar: boolean,
  snackbarContent: string,
  isViewReport: boolean,
  reportCurrentTab: number,
  questionList: IQuestion[],
  questionsWithAnwser: IQuestion[],
  currentResultHeaderIndex: number,
  currentQuestionIndex: number,
  currentUserIndex: number,
  currentTaskName: string,
  usersAnswer: IAnswersByAccount[],
  individualQuestions: IQuestion[],
  language: string
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListApiCallId: string = "";
  getMilestoneApiCallId: string = "";
  getTaskDetail: string = ""
  getTaskListQuestionDetailCallId: string = "";
  getIndividualCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      loading: false,
      selectedMilestone: "",
      openMilestoneOptions: false,
      milestoneOptions: [],
      isLoadingTaskList: false,
      taskList: [],
      taskSelected: "",
      openSnackbar: false,
      snackbarContent: "",
      isViewReport: false,
      reportCurrentTab: 0,
      questionList: [],
      questionsWithAnwser: [],
      currentResultHeaderIndex: 0,
      currentQuestionIndex: 0,
      currentUserIndex: 0,
      usersAnswer: [],
      currentTaskName: "",
      individualQuestions: [],
      language: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End

  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getAllMileStones()
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.getTaskListApiCallId) {
        this.handleGetTaskList(responseJson);
      }
      if(apiRequestCallId === this.getMilestoneApiCallId) {
        this.handleMilestoneGetResponse(responseJson)
      }
      if(apiRequestCallId === this.getTaskDetail) {
        this.handleTaskDetailGetResponse(responseJson)
      }
      if(apiRequestCallId === this.getTaskListQuestionDetailCallId){
        this.handleTaskListQuestionDetail(responseJson)
      }
      if(apiRequestCallId === this.getIndividualCallId){
        this.handleIndividualData(responseJson)
      }
    }
  }

  onMilestoneSelected = (milestone: IMilestone) => {
    this.setState({
      selectedMilestone: milestone.name,
      openMilestoneOptions: false,
      isLoadingTaskList: true
    })
    this.getTaskListApi(milestone.id.toString())
  }
  
  onTaskSelected = (taskId: string) => {
    if(this.state.taskSelected == taskId){
      this.setState({
        taskSelected: "",
        questionList: []
      })
    } else {
      this.setState({
        taskSelected: taskId,
      })
      this.getTaskDetailApi(taskId)
      this.getIndividualDetail(taskId)
    }
  }

  handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      openSnackbar: false
    })
  };

  handleClickViewReport = (event: any) => {
    this.setState({
      isViewReport: true,
      reportCurrentTab: 0
    })
    event.stopPropagation();
  }

  exitViewReportMode = () => {
    this.setState({
      isViewReport: false,
      reportCurrentTab: 0
    })
  }

  handleCurrentTabChange = (event: any, newValue: any) => {
    this.setState({
      reportCurrentTab: newValue
    })
    if(newValue === 1){
      this.getTaskListQuestionDetail(this.state.taskSelected)
    }
  };

  handleGetTaskList = (responseJson: any) => {
    this.setState({
      isLoadingTaskList: false
    })
    if(responseJson.data){
      this.setState({
        taskList: responseJson.data,
      })
    } else {
      this.setState({
        taskList: [],
        taskSelected: '',
        openSnackbar: true,
        snackbarContent: responseJson.error || "Internal server error"
      }) 
    }
  }

  handleMilestoneGetResponse = (responseJson: any) => {
    if(responseJson.milestones){
      this.setState({
        milestoneOptions: responseJson.milestones
      })
    }
  }

  handleLanguageSwitch = (language: string) => {
    this.setState({ language: language})
  }
  
  handleTaskDetailGetResponse = (responseJson: any) => {
    if(responseJson.data){
      this.setState({
        questionList: responseJson.data.attributes.question_types.data
      })
    }
  }

  handleTaskListQuestionDetail = (responseJson: any) => {
    if(responseJson.data){
      this.setState({
        questionsWithAnwser: responseJson.data.attributes.question_types
      })
    }
  }

  handleIndividualData = (responseJson: any) => {
    if(responseJson.data){
      this.setState({
        usersAnswer: responseJson.data.attributes.answers_by_account,
        currentTaskName: responseJson.data.attributes.task_name,
        individualQuestions: responseJson.data.attributes.question_types
      })
    }
  }

  handleResultHeaderClick = (index: number) => {
    this.setState({
      currentResultHeaderIndex: index
    })
  }

  handleChangeQuestionDropdown = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    const index = this.state.questionsWithAnwser.findIndex(question => {
      return question.attributes.question === value
    })
    this.setState({
      currentQuestionIndex: index
    })
  };

  processAnswerListSubjective = (list: IAnswer[]) => {
      let keys: {[x: string]: number} = {}
      list.forEach(answer => {
        if(keys[answer.answer.answer_text]) {
          keys[answer.answer.answer_text] = keys[answer.answer.answer_text] + 1
        } else {
          keys[answer.answer.answer_text] = 1
        }
      })
      return keys
  }

  processAnswerListMultipleChoice = (list: IAnswer[]) => {
    //find accounts
    // const accounts = []
    let result: {[x: string]: string}  = {}
    list.forEach(answer => {
      if(result[answer.account.id_number]){
        result[answer.account.id_number] = result[answer.account.id_number] + "---" + answer.answer.answer_text
      } else {
        result[answer.account.id_number] = answer.answer.answer_text
      }
    })

    let keys: {[x: string]: number} = {}
    Object.values(result).forEach((combinedAnswer: string) => {
      if(keys[combinedAnswer]) {
        keys[combinedAnswer] = keys[combinedAnswer] + 1
      } else {
        keys[combinedAnswer] = 1
      }
    })
    return keys
  }

  processAnswerListRangeBased = (list: IAnswer[]) => {
    let keys: {[x: string]: number} = {}
    list.forEach(answer => {
      if(answer.answer.range_value === null){
        return;
      }
      if(keys[answer.answer.range_value]) {
        keys[answer.answer.range_value] = keys[answer.answer.range_value] + 1
      } else {
        keys[answer.answer.range_value] = 1
      }
    })
    return keys
}

  onPreviousQuestion = () => {
    this.setState(prev => {
      return {
        currentQuestionIndex: prev.currentQuestionIndex > 0 ? prev.currentQuestionIndex - 1 : 0
      }
    })
  }

  onNextQuestion = () => {
    this.setState(prev => {
      return {
        currentQuestionIndex: prev.currentQuestionIndex < this.state.questionsWithAnwser.length - 2 ? prev.currentQuestionIndex + 1 : this.state.questionsWithAnwser.length - 1
      }
    })
  }

  onPreviousUser = () => {
    this.setState(prev => {
      return {
        currentUserIndex: prev.currentUserIndex > 0 ? prev.currentUserIndex - 1 : 0
      }
    })
  }

  onNextUser = () => {
    const totalAnswers = this.state.questionsWithAnwser[this.state.currentQuestionIndex].answers.length;
    this.setState(prev => {
      return {
        currentUserIndex: prev.currentUserIndex < totalAnswers - 1 
          ? prev.currentUserIndex + 1 
          : totalAnswers - 1
      }
    })
  }

  getTaskListApi = async(milestoneID: string) => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    const requestTaskListMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestTaskListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestTaskListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/task_lists/milestone_task_list/${milestoneID}`
    );
    requestTaskListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getTaskListApiCallId = requestTaskListMessage.messageId;
    runEngine.sendMessage(requestTaskListMessage.id, requestTaskListMessage);
  }

  getAllMileStones = () => {
    let parseData;
    let studyNameStore = localStorage.getItem("studyName")
    if (studyNameStore != null) {
      parseData = JSON.parse(studyNameStore)
    }
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    const requestAllMilestoneMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMilestoneApiCallId = requestAllMilestoneMessage.messageId;
    requestAllMilestoneMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestAllMilestoneMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllMileStoneEndPoint + parseData.id
    );
    requestAllMilestoneMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestAllMilestoneMessage.id, requestAllMilestoneMessage);
  }

  getTaskDetailApi = async(taskId: string) => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    const requestTaskDetailMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestTaskDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestTaskDetailMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/task_lists/${taskId}`
    );
    requestTaskDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getTaskDetail = requestTaskDetailMessage.messageId;
    runEngine.sendMessage(requestTaskDetailMessage.id, requestTaskDetailMessage);
  }

  getTaskListQuestionDetail = async(taskId: string) => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    const requestQuestionDetailMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestQuestionDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestQuestionDetailMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/patient_task_list_details/questions/${taskId}`
    );
    requestQuestionDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getTaskListQuestionDetailCallId = requestQuestionDetailMessage.messageId;
    runEngine.sendMessage(requestQuestionDetailMessage.id, requestQuestionDetailMessage);
  }

  getIndividualDetail = async(taskId: string) => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/patient_task_list_details/individual/${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getIndividualCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End