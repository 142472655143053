// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
const mockData = require("./mock-data.json");
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id: string;
  navigation: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  language: string;
  token: string;
  showTextFieldSection: boolean;
  showSideDrawer: boolean;
  addPatientDrawerOpen: boolean;
  patientEmailError: boolean;
  emailErrorText: string;
  isLoader: boolean;
  patientEmailAddress: string;
  patientEmailAddressError: string;
  newConfirmPatientEmailAddress: string;
  newConfirmPatientEmailAddressError: string;
  patientErrorMsg: string
  patinetFullName: string;
  patinetFullNameError: string;
  patinetIdNumber: string;
  patinetIdNumberError: string;
  studyFullData: [{
    type: string;
    id: string;
    attributes: {
      account_id: number;
      study_name: string;
      study_description: string;
      study_number: string;
      archived: boolean;
      community: boolean;
      published: boolean;
      gamification: boolean;
      chat: boolean;
      milestone_managements: {
        data: {
          type: string;
          id: string;
          attributes: {
            name: string;
            id: number;
            end_date: string;
            start_date: string;
            milestone_description: string;
          };
        }
      };
      faq_managements: {
        data: {
          type: string;
          id: string;
          attributes: {
            id: number;
            answer: string;
            question: string;
            images: {
              url: string;
            };
            videos: {
              url: string;
            }
          };
        }
      };
    };
  }] | "",
  selectedStudy: any,
  selectedSiteID: number,
  emailScreen: boolean;
  nameScreen: boolean,
  postPatId: any,
  tabs: { label: string, value: string | number }[];
  selectedTab: string | number;
  tableData: {
    columns: { [name: string]: any }[];
    rows: { [key: string]: any }[];
  }
  rows: any;
  columnName: any;
  // Customizable Area End
}
interface SS {
  id: any;
// Customizable Area Start
// Customizable Area End
}

export default class PatientController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getStudyDataApiCallId: string = "";
  patchPatientRegAPIid: string = '';
  getPatientDataApiCallID: string = "";
  getPatientTasklistDataApiCallID: string = "";
  checkEmailValidationApiCallID:string="";
  getTrailVisitScheduleCallID:string="";
  // Customizable Area End
  
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      loading: false,
      language: "",
      showTextFieldSection: true,
      showSideDrawer: false,
      addPatientDrawerOpen: false,
      patientEmailError: false,
      emailErrorText: '',
      isLoader: false,
      patientEmailAddress: '',
      patientEmailAddressError: '',
      newConfirmPatientEmailAddress: '',
      newConfirmPatientEmailAddressError: '',
      patinetFullName: '',
      patinetFullNameError: '',
      patinetIdNumber: '',
      patinetIdNumberError: '',
      studyFullData: '',
      selectedStudy: '',
      selectedSiteID: 0,
      emailScreen: true,
      nameScreen: false,
      patientErrorMsg: "",
      postPatId: '',
      tabs: [
        { label: "Trail Visit Schedule", value: "Trail Visit Schedule" },
        { label: "Task List", value: "Task List" }
      ],
      selectedTab: "Trail Visit Schedule",
      tableData: {
        columns: [],
        rows: []
      },
      rows: [],
      columnName: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End

  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    let studyNameStore = await getStorageData("studyName")
    let parseData = JSON.parse(studyNameStore)
    this.setState({ selectedStudy: parseData.id, selectedSiteID: parseData.attributes?.site_ids.data[0].id })

    let patIdStore = await getStorageData("patId")
    this.getPatientDetails(patIdStore)
    this.callTrailVisitScheduleApi()
    // Customizable Area End
  }
  
  // Customizable Area Start
  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId != null) {
       if (apiRequestCallId === this.getStudyDataApiCallId && responseJson) {
          this.setState({ studyFullData: responseJson.data });
        }
        else if (apiRequestCallId === this.patchPatientRegAPIid && responseJson) {
          this.handlePatchPatientDetails(responseJson);
        }
        else if (apiRequestCallId === this.getPatientDataApiCallID && responseJson) {
          console.log(responseJson, "res=========>");
        }
        else if (apiRequestCallId === this.getPatientTasklistDataApiCallID) {
          console.log(responseJson, "res11=========>");
          this.callResponceOfTasklist(responseJson)
        }else if(apiRequestCallId === this.checkEmailValidationApiCallID){
          this.callResponceEmailValidation(responseJson)
        }else if(apiRequestCallId === this.getTrailVisitScheduleCallID){
          this.callResponceOfTrailVisitSchedule(responseJson)
        }

      }

    }

  }

  handlePatchPatientDetails = (responseJson: any) => {
    if (responseJson && responseJson.meta) {
      this.setState({
        patinetIdNumberError: responseJson.meta.errors,
      });
    }

    if (responseJson.data) {
      this.setState({
        nameScreen: false,
        emailScreen: true,
        patientEmailAddress:"",newConfirmPatientEmailAddress:"",patinetFullName:"",patinetIdNumber:"",patientErrorMsg:""
      })
    }

  }
  callResponceEmailValidation=(responseJson:any)=>{
    if (responseJson && responseJson.meta) {
      console.log("res1",responseJson)
      if(responseJson.meta.message === "New Patient to App"){
       
        this.setState({
          nameScreen: true,
          emailScreen: false,
          isLoader: false
        })
        setStorageData("patId", responseJson.data.id);
      }else if(responseJson.meta.message === "Patient already added in the selected study" ||
      responseJson.meta.message === "The patient is already registered in another site")
        {
  this.setState({
    patientEmailError: true,
    patientEmailAddressError: responseJson.meta.message,
    isLoader: false
  });
}else if(responseJson.meta.message === "Patient already registred in site you can continue to register to current study")
      {  
        this.setState({
          nameScreen: true,
          emailScreen: false,
          isLoader: false,
          patinetFullName:responseJson.meta.patient.data.attributes.full_name
        })
        setStorageData("patId", responseJson.data.id);
      }
     
    }

    if (responseJson.patient) {
      this.setState({
        nameScreen: true,
        emailScreen: false,
        isLoader: false,
        patinetFullName:responseJson.patient.data.attributes.full_name
      })
      setStorageData("patId", responseJson.data.id);
    }
  }
  
  callResponceOfTasklist = (data: any) => {
    localStorage.setItem("patientData",JSON.stringify(data))

    const taskNames: any[] = [{ name: "Patient Name", value: "" },
    {
      name: "ID", value: ""
    },];

    // Iterate through each object in the data array
    data?.forEach((item: { [x: string]: any[]; }) => {
      ['attended_task_lists', 'not_attended_task_lists', 'remaining_task_lists'].forEach(key => {
        item[key].forEach((task: { task_name: any; }) => {
          const existingTask = taskNames.find(t => t.value === task.task_name);
          if (!existingTask) {
            taskNames.push({ name: `T${(taskNames.length - 2) + 1}`, value: task.task_name });
          }
        });
      });
    });


    this.setState({ columnName: taskNames })
    const rows: any = [];

    data?.forEach((item: { user_data: { full_name: any; id_number: any;quickblox_id:any; }; attended_task_lists: any[]; not_attended_task_lists: any[]; remaining_task_lists: any[]; }) => {
      type RowData = {
        [key: string]: any;
      };

      const rowData: RowData = {
        'Patient Name': item.user_data.full_name,
        'ID': item.user_data.id_number,
        'quickBlox':item?.user_data?.quickblox_id
      };
      item.attended_task_lists.forEach((task: any, index: number) => {

        let columnName = `T${index + 1}`;
        rowData[columnName] = "Attended";

      });

      item.not_attended_task_lists?.forEach((task: any, index: any) => {
        const columnName = `T${item.attended_task_lists.length + index + 1}`;
        rowData[columnName] = "Not Attended";
      });

      item.remaining_task_lists?.forEach((task: any, index: any) => {
        const columnName = `T${item.attended_task_lists.length + item.not_attended_task_lists.length + index + 1}`;
        rowData[columnName] = "Remaining";
      });

      rows.push(rowData);

    });

    const tableData = {
      "columns": taskNames,
      "rows": rows
    }
    this.setState({ tableData: tableData },)
  }
  callResponceOfTrailVisitSchedule = (data1: any) => {
    localStorage.setItem("patientData", JSON.stringify(data1));
  
    const taskNames1: any[] = [
      { name: "Patient Name", value: "" },
      { name: "ID", value: "" },
    ];
  
    // Track the maximum number of columns needed
    let maxColumns = 0;
  
    data1.forEach((item: { [x: string]: any[] }) => {
      const attendedLength = item.attended_schedules.length;
      const notAttendedLength = item.not_attended_schedules.length;
      const remainingLength = item.remaining_schedules.length;
  
      // Calculate the total number of unique tasks
      const totalTasks = attendedLength + notAttendedLength + remainingLength;
  
      if (totalTasks > maxColumns) {
        maxColumns = totalTasks;
      }
    });
  
    // Generate column names based on the maximum number of tasks 
    for (let i = 1; i <= maxColumns; i++) {
      taskNames1.push({ name: `V${i}` });
    }
  
    this.setState({ columnName: taskNames1 });
    const rows: any = [];
  
    data1.forEach((item: { user_data: { full_name: any; id_number: any; email: string; quickblox_id: string; }; attended_schedules: any[]; not_attended_schedules: any[]; remaining_schedules: any[]; }) => {
      type RowData = {
        [key: string]: any;
      };
  
      const rowData: RowData = {
        'Patient Name': item.user_data.full_name,
        'ID': item.user_data.id_number,
        'quickBlox': item?.user_data?.quickblox_id
      };
  
      item.attended_schedules.forEach((task: any, index: number) => {
        const columnName = `V${index + 1}`;
        rowData[columnName] = "Attended";
      });
  
      item.not_attended_schedules.forEach((task: any, index: number) => {
        const columnName = `V${item.attended_schedules.length + index + 1}`;
        rowData[columnName] = "Not Attended";
      });
  
      item.remaining_schedules.forEach((task: any, index: number) => {
        const columnName = `V${item.attended_schedules.length + item.not_attended_schedules.length + index + 1}`;
        rowData[columnName] = "Remaining";
      });
  
      rows.push(rowData);
    });
  
    const tableData = {
      "columns": taskNames1,
      "rows": rows
    };
  
    this.setState({ tableData: tableData });
  }; 
  
  handleAddPatientDetails = (responseJson: any) => {
    if (responseJson && responseJson.meta) {
      this.setState({
        patientEmailError: true,
        patientEmailAddressError: responseJson.meta.message,
        isLoader: false
      });
    }

    if (responseJson.data) {
      this.setState({
        nameScreen: true,
        emailScreen: false,
        isLoader: false
      })
      setStorageData("patId", responseJson.data.id);
    }
  }

  handlePatientEmailAddress = (value: any) => {
    const strongEmailRegex = configJSON.emailRegex;
    this.setState({
      patientEmailAddress: value,
      patientEmailAddressError: strongEmailRegex.test(value)
        ? ""
        : "Email must be valid",
      patientErrorMsg: ''
    })
  }

  handleNewConfirmPatientEmailAddress = (value: any) => {
    this.setState({
      newConfirmPatientEmailAddress: value,
      newConfirmPatientEmailAddressError: this.state.patientEmailAddress === value ? "" : 'Email does not match'
    })
  }

  handleContinueButton = () => {
    const { patientEmailAddress, newConfirmPatientEmailAddress } = this.state;
    if (!patientEmailAddress && !newConfirmPatientEmailAddress) {
      this.setState({ patientEmailAddressError: 'Email is required', newConfirmPatientEmailAddressError: 'Confirm Email is required', patientErrorMsg: "" });
    } else if (patientEmailAddress !== newConfirmPatientEmailAddress) {
      this.setState({ newConfirmPatientEmailAddressError: 'Email does not match' });
    }
    else {
      {
        this.callCheckEmailApi();
        this.setState({
          isLoader: true,
        });
      }
    }
  }
  callCheckEmailApi=()=>{
    let token = localStorage.getItem("token")
   

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patientEmailValidation+'?email='+this.state.patientEmailAddress+'&role_name='+"Patient"+'&site_id='+this.state.selectedSiteID+'&study_ids='+this.state.selectedStudy+''
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
   
    this.checkEmailValidationApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleAddPatientButton = () => {
    let hasError = false;
    if (this.state.patinetFullName.trim() === "") {
        this.setState({ patinetFullNameError: "Full Name is required" });
        hasError = true;
    } else {
        this.setState({ patinetFullNameError: "" });
    }
  
    if (this.state.patinetIdNumber === "") {
        this.setState({ patinetIdNumberError: "Id is required" });
        hasError = true;
    } else {
        this.setState({ patinetIdNumberError: "" });
    }
  
    if (!hasError) {
        this.patchPatientRegistration();
        this.setState({
            isLoader: false,
            nameScreen: false,
            emailScreen: true,
            addPatientDrawerOpen: false,
        });
    }
    alert("Patient Added Successfully")
}
  handlePatientFullName = (value: any) => {
    const strongNameRegex = configJSON.nameRegex;
    const isLengthValid = value.trim().length > 4;

    this.setState({
      patinetFullName: value,
      patinetFullNameError: strongNameRegex.test(value) && isLengthValid
        ? ""
        : 'Please enter a valid full name'
    })
  }

  handlePatientIdNumber = (value: any) => {
    const numericValue = value.replace(/\D/g, '');
    const strongIdRegex = configJSON.idRegex;
    const isLengthValid = numericValue.length >= 3 && numericValue.length <= 6; 

    if (numericValue.length <= 6) {
    this.setState({
        patinetIdNumber: numericValue,
        patinetIdNumberError: strongIdRegex.test(numericValue) && isLengthValid
        ? ""
        : 'Please enter a valid ID between 3 and 6 digits'
    })
    }
}

handleLanguageSwitch = (language: string) => {
  this.setState({ language: language})
}
  handleAddPatDrawerOpen = () => {
    this.getStudyDataIndexDetail()
    this.setState({ addPatientDrawerOpen: !this.state.addPatientDrawerOpen, });
  };

  handleCloseCancel = () => {
    if (this.state.emailScreen) {
      this.setState({
        addPatientDrawerOpen: false,
        patientEmailAddress: "",
        newConfirmPatientEmailAddress: "",
        patientEmailAddressError: "",
        newConfirmPatientEmailAddressError: "",
        isLoader: false,
      });
    } else {
      this.setState({
        addPatientDrawerOpen: false,
        emailScreen: true,
        nameScreen: false,
        showTextFieldSection: true,
        patientEmailAddress: "",
        newConfirmPatientEmailAddress: "",
        patinetFullName: "",
        patinetIdNumber: "",
        patientEmailAddressError: "",
        newConfirmPatientEmailAddressError: "",
        patinetFullNameError: "",
        patinetIdNumberError: "",
        patientErrorMsg: "",
        isLoader: false,
      });
    }
  };
  
  handleDrawerCloseAdd = () => {
    this.setState({
      addPatientDrawerOpen: false,
      patientEmailAddressError: '', newConfirmPatientEmailAddressError: '', patinetFullNameError: '', patinetIdNumberError: '', isLoader: false
    });
  };

  handleEdit = () => {
    this.setState({
      nameScreen: false, emailScreen: true
    })
  }
  callTrailVisitScheduleApi=async()=>{
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVisitScheduleAPiEndPoint}/${this.state.selectedStudy}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    this.getTrailVisitScheduleCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllTasklist = async() => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTasklistAPiEndPoint}/${this.state.selectedStudy}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    this.getPatientTasklistDataApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPatientDetails = async (id: number) => {
    if (!id) {
      return
    }
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPatientApiEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    this.getPatientDataApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudyDataIndexDetail = async () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudiesApiEndPoint + this.state.selectedStudy
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    this.getStudyDataApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  patchPatientRegistration = async () => {
    setStorageData("full_name", this.state.patinetFullName)
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };

    let formDataCampaignUpdate = {
      "email": this.state.patientEmailAddress.toLowerCase(),
      "role_name": "Patient",
      "site_id": this.state.selectedSiteID,  
      "study_ids": this.state.selectedStudy,
      "full_name": this.state.patinetFullName,
      "id_number": this.state.patinetIdNumber,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addPatientRegistrationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaignUpdate)
    );
    this.patchPatientRegAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSelectTab = (value: string | number) => {
    this.setState({ selectedTab: value });
    if (value === "Trail Visit Schedule") {
      this.setState({tableData: {
        columns: [],
        rows: []
      }})
      this.callTrailVisitScheduleApi();
    } else {
      this.setState({tableData: {
        columns: [],
        rows: []
      }})
      this.getAllTasklist()
    }
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End